<template>
  <div class="position-relative">
    <label for="" class="w-100">
      <input
        v-if="mask.length > 0"
        v-mask="mask"
        :type="typeSelected"
        :placeholder="placeholder"
        :value="value"
        @input="$emit('input', $event.target.value)"
        @blur="$emit('blur')"
        :class="{ 'form-group--error': error }"
      >
      <input
        v-else
        :type="typeSelected"
        :placeholder="placeholder"
        :value="value"
        @input="$emit('input', $event.target.value)"
        @blur="$emit('blur')"
        :class="{ 'form-group--error': error }"
      >
      <span class="f-sm fontPoppins fw-500" v-if="error">{{ errorMessage }}</span>
    </label>
    <div class="icon">
      <ErrorInputIcon v-if="error" />
      <b-button variant="default" @click="showPassword" v-else>
        <slot name="icon" ></slot>
      </b-button>
    </div>
  </div>
</template>

<script>
import ErrorInputIcon from '@/components/icons/errorInputIcon.vue';

export default {
  name: 'InputIcon',
  components: {
    ErrorInputIcon,
  },
  props: {
    mask: {
      type: String,
      default: '',
    },
    errorMessage: {
      type: String,
      default: '',
    },
    error: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      typeSelected: this.type,
    };
  },
  methods: {
    showPassword() {
      this.typeSelected = this.typeSelected === 'password' ? 'text' : 'password';
    },
  },
};
</script>

<style lang="scss" scoped>
  label {
    position: relative !important;
  }
  span {
    position: absolute;
    left: 5px;
    bottom: -20px;
    color: #F31155;
    letter-spacing: 0.5px;
  }
  .icon {
    position: absolute;
    right: 10px;
    top: 10px;

    button {
      padding: 0 !important;
    }
  }
</style>
