import CryptoJS from 'crypto-js';
import Cookie from 'js-cookie';

export const getCookie = (key) => Cookie.get(key);

export const setCookie = (key, value) => Cookie.set(key, value);

export const removeCookie = (key) => Cookie.remove(key);

export const setLocalStorage = (key, value) => localStorage.setItem(key, value);

export const getLocalStorage = (key) => localStorage.getItem(key);

export const removeLocalStorage = (key) => localStorage.removeItem(key);

export const encrypt = (data) => {
  const token = getCookie('token_vend_sucess');
  const ciphertext = CryptoJS.AES.encrypt(data, token).toString();
  return ciphertext;
};

export const decrypt = (data) => {
  const token = getCookie('token_vend_sucess');
  const bytes = CryptoJS.AES.decrypt(data, token);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
};
