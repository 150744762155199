import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import ProvinceModule from './modules/provinceModule';
import PaymentModule from './modules/paymentModule';
import CartModule from './modules/cartModule';
import UserModule from './modules/userModule';
import FinancingModule from './modules/financingModule';

Vue.use(Vuex);

const persistOptions = {
  key: 'vendedoras_sucesso',
  storage: window.sessionStorage,
  paths: ['cart', 'user'],
};

const store = new Vuex.Store({
  modules: {
    provinces: ProvinceModule,
    payment: PaymentModule,
    cart: CartModule,
    user: UserModule,
    financing: FinancingModule,
  },
  plugins: [createPersistedState(persistOptions)],
});

export default store;
