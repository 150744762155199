<template>
  <div>
    <b-modal :id="id">
      <template v-slot:modal-title>
        <div class="modal-title">
          <h4 class="mb-0 ml-2">
            {{ title }}
          </h4>
        </div>
      </template>
      <div class="text-center">
        <span>
          {{ question }}
        </span> <br>
        <strong>
          {{ detail }}
        </strong>
      </div>
      <template v-slot:modal-footer>
        <div class="text-right">
          <b-button
            variant="outline-secondary"
            @click="close"
            class="mr-2"
          >
            Cancelar
          </b-button>
          <b-button
            variant="danger"
            @click="$emit('confirm')"
          >
            Excluir
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'ModalConfirm',
  props: {
    id: {
      type: String,
      default: 'confirm-delete-default',
    },
    title: {
      type: String,
      default: 'Deseja realmente excluir',
    },
    question: {
      type: String,
      default: 'Deseja realmente excluir',
    },
    detail: {
      type: String,
      default: '',
    },
  },
  methods: {
    close() {
      this.$bvModal.hide(this.id);
    },
  },
};
</script>
