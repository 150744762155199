import axios from 'axios';
import Cookies from 'js-cookie';
import router from './router';
// eslint-disable-next-line import/no-cycle
// import LoginService from '@/Services/LoginService';

const axiosInstance = axios.create({
  withCredentials: false,
  baseURL: process.env.VUE_APP_API,
});

axiosInstance.interceptors.request.use((config) => {
  if (config && config.url === '/login') {
    return config;
  }

  const token = Cookies.get('token_vend_sucess');
  if (token !== undefined && token !== null) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
}, (error) => {
  if (error.response.status !== 401) {
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }

  return Promise.reject(error);
});

axiosInstance.interceptors.response.use((response) => response, (error) => {
  if (error.response.status === 401) {
    router.push({ name: 'AuthLogin' });
  }

  return Promise.reject(error);
});

export default axiosInstance;
