<template>
  <div>
    <div :class="{ 'mb-3': selects.length > 0 }">
      <b-badge
        v-for="(option, index) in selects"
        pill
        :key="index"
        :style="{ backgroundColor: option.color_hex }"
        class="mr-1 mb-1"
        @click="edit ? removeTag(index) : null"
      >
        {{ option.name }}
        <b-icon icon="x" v-if="edit" />
      </b-badge>
    </div>
    <div class="cloud-tags p-3" v-if="edit">
      <b-badge
        pill
        variant="dark"
        v-for="(option, index) in options"
        :key="index"
        class="mr-1 mb-1"
        @click="addTag(index)"
      >
        {{ option.name }}
      </b-badge>
    </div>
    <div class="text-right mt-3" v-if="edit">
      <b-button size="sm" variant="primary" v-b-modal.create-interest>
        <b-icon icon="plus" />
        Adicionar interesses
      </b-button>
    </div>

    <b-modal id="create-interest">
      <template v-slot:modal-title>
        <div class="modal-title">
          <HeartIcon />
          <h4 class="mb-0 ml-2">
            Cadastrar novo interesse
          </h4>
        </div>
      </template>
      <b-form>
        <b-row>
          <b-col lg="12">
            <InputLabel
              label="Interesse"
              :error="$v.payload.name.$error"
              errorMessage="Informe o nome do interesse"
              :value="$v.payload.name.$model"
              @input="(value) => $v.payload.name.$model = value"
            />
          </b-col>
          <b-col lg="12" class="text-center" v-if="payload.name.length > 0">
            <b-badge pill :style="{ backgroundColor: payload.color_hex }">
              {{ payload.name }}
            </b-badge>
          </b-col>
          <b-col lg="12" v-if="payload.name.length > 0">
            <LvColorPicker
              label="Selecione uma cor"
              :value="payload.color_hex"
              :clearable="true"
              :bottomBar="true"
              @input="(value) => payload.color_hex = value"
            />
          </b-col>
        </b-row>
      </b-form>
      <template v-slot:modal-footer>
        <div class="text-right">
          <b-button
            variant="outline-secondary"
            @click="$bvModal.hide('create-interest')"
            class="mr-2"
          >
            Cancelar
          </b-button>
          <b-button
            variant="primary"
            @click="save"
            :disabled="loading"
          >
            Salvar
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import InterestService from '@/services/interest';
import HeartIcon from '@/components/icons/heartIcon.vue';
import InputLabel from './inputLabel.vue';

export default {
  name: 'InputTag',
  components: {
    HeartIcon,
    InputLabel,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    edit: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      selects: [],
      loading: false,
      options: [],
      payload: {
        name: '',
        color_hex: '#7e57c2',
      },
    };
  },
  validations() {
    return {
      payload: {
        name: {
          required,
        },
      },
    };
  },
  methods: {
    getInterest() {
      InterestService.getList().then(({ data }) => {
        this.options = data;
        this.selects = this.options.filter((option) => this.value.includes(option.id));
        this.options = this.options.filter((option) => !this.value.includes(option.id));
      });
    },
    addTag(index) {
      this.selects.push(this.options[index]);
      this.options.splice(index, 1);
      const ids = this.selects.map((select) => select.id);
      this.$emit('change', ids);
    },
    removeTag(index) {
      this.options.push(this.selects[index]);
      this.selects.splice(index, 1);
      const ids = this.selects.map((select) => select.id);
      this.$emit('change', ids);
    },
    save() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      InterestService.create(this.payload).then(({ data }) => {
        this.options = data;
        this.$bvModal.hide('create-interest');
        this.payload = {
          name: '',
          color_hex: '#7e57c2',
        };
        this.$v.$reset();
        this.getInterest();
        this.$bvToast.toast('Interesse cadastrado com sucesso', {
          title: 'Sucesso',
          variant: 'success',
          solid: true,
        });
      });
    },
  },
  mounted() {
    this.getInterest();
  },
};
</script>

<style lang="scss" scoped>
.cloud-tags {
  background: #F3F5F9;
  border-radius: 8px;
}

.badge {
  font-family: 'Inter', sans-serif !important;
  font-weight: 400 !important;
  cursor: pointer;
  height: 26px;
  line-height: 20px;
}
</style>
