const state = {
  payment: {
    price: 0,
    name: '',
    discount: 0,
    details: [],
  },
};

const getters = {
  payment: (state) => state.payment,
};

const mutations = {
  setPayment(state, payment) {
    state.payment = payment;
  },
};

const actions = {
  setPayment({ commit }, payment) {
    commit('setPayment', payment);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
