import http from '@/http';

export default class Base {
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
  }

  get(id) {
    return http.get(`${this.baseUrl}/${id}`);
  }

  getList(params) {
    return http.get(`${this.baseUrl}`, { params });
  }

  create(payload) {
    return http.post(`${this.baseUrl}`, payload);
  }

  edit(payload, id = null) {
    if (id !== null) {
      return http.put(`${this.baseUrl}/${id}`, payload);
    }

    return http.put(`${this.baseUrl}/${payload.id}`, payload);
  }

  delete(id) {
    return http.delete(`${this.baseUrl}/${id}`);
  }

  post(url, payload) {
    return http.post(`${this.baseUrl}/${url}`, payload);
  }

  getWithUrl(url) {
    return http.get(`${this.baseUrl}/${url}`);
  }
}
