import Vue from 'vue';
import VueRouter from 'vue-router';
import Guard from '@/services/middleware';
import PaymentNotApproved from '../views/PaymentNotApproved.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: () => import('../views/Layout.vue'),
    name: 'Home',
    beforeEnter: Guard.payment,
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: () => import('../views/Dashboard.vue'),
      },
      {
        path: 'profile/:id',
        name: 'Profile',
        component: () => import('@/pages/profile/profile-show.vue'),
      },
      {
        path: 'not-approved',
        name: 'PaymentNotApproved',
        component: PaymentNotApproved,
      },
      {
        path: 'clients',
        name: 'ClientsList',
        component: () => import('@/pages/clients/clients-list.vue'),
        meta: {
          title: 'Clientes',
        },
      },
      {
        path: 'clients/:id',
        name: 'ClientsShow',
        component: () => import('@/pages/clients/clients-show.vue'),
      },
      {
        path: 'products',
        name: 'ProductsList',
        component: () => import('@/pages/products/products-list.vue'),
        meta: {
          title: 'Produtos e serviços',
        },
      },
      {
        path: 'suppliers',
        name: 'SuppliersList',
        component: () => import('@/pages/suppliers/suppliers-list.vue'),
        meta: {
          title: 'Fornecedores',
          subtitle: 'Cadastre e gerencie seus fornecedores',
        },
      },
      {
        path: 'suppliers/:id',
        name: 'SuppliersShow',
        component: () => import('@/pages/suppliers/supplier-show.vue'),
      },
      {
        path: 'stock',
        name: 'StockList',
        component: () => import('@/pages/stock/stock-list.vue'),
        meta: {
          title: 'Estoque',
          subtitle: 'Cadastre e gerencie seu estoque de produtos',
        },
      },
      {
        path: 'sales',
        name: 'SalesList',
        component: () => import('@/pages/sales/sales-list.vue'),
        meta: {
          title: 'Vendas',
          subtitle: 'Gerencie suas vendas',
        },
      },
      {
        path: 'sales/:id',
        name: 'SalesShow',
        component: () => import('@/pages/sales/sales-show.vue'),
      },
      {
        path: 'purchases',
        name: 'PurchasesList',
        component: () => import('@/pages/purchases/purchases-list.vue'),
        meta: {
          title: 'Compras',
          subtitle: 'Gerencie suas compras',
        },
      },
      {
        path: 'purchases/:id',
        name: 'PurchasesShow',
        component: () => import('@/pages/purchases/purchases-show.vue'),
      },
      {
        path: 'pay-accounts',
        name: 'PayAccountsList',
        component: () => import('@/pages/financing/pay-accounts-list.vue'),
        meta: {
          title: 'Contas a pagar',
          subtitle: 'Gerencie suas contas a pagar',
        },
      },
      {
        path: 'receive-accounts',
        name: 'ReceiveAccountsList',
        component: () => import('@/pages/financing/receive-accounts-list.vue'),
        meta: {
          title: 'Contas a receber',
          subtitle: 'Gerencie suas contas a receber',
        },
      },
      {
        path: 'cash-flow',
        name: 'CashFlow',
        component: () => import('@/pages/financing/cash-flow.vue'),
        meta: {
          title: 'Fluxo de caixa',
          subtitle: 'Acompanhe o seu fluxo de caixa',
        },
      },
    ],
  },
  {
    path: '/login',
    component: () => import('@/pages/auth/layout.vue'),
    children: [
      {
        path: '/',
        name: 'AuthLogin',
        component: () => import('@/pages/auth/login.vue'),
      },
      {
        path: 'register',
        name: 'AuthRegister',
        component: () => import('@/pages/auth/register.vue'),
      },
      {
        path: 'forgot-password',
        name: 'AuthForgot',
        component: () => import('@/pages/auth/forgotPassword.vue'),
      },
      {
        path: 'reset-password/:token/:email',
        name: 'AuthReset',
        component: () => import('@/pages/auth/resetPassword.vue'),
      },
      {
        path: 'reset-password-confirm',
        name: 'AuthResetConfirm',
        component: () => import('@/pages/auth/resetPasswordConfirm.vue'),
      },
      {
        path: 'validate',
        name: 'AuthValidate',
        component: () => import('@/pages/auth/validate.vue'),
      },
      {
        path: 'check-account/:id/:hash',
        name: 'AuthCheckAccount',
        component: () => import('@/pages/auth/checkAccount.vue'),
      },
    ],
  },
  {
    path: '/checkout',
    component: () => import('@/pages/checkout/layout.vue'),
    beforeEnter: Guard.auth,
    children: [
      {
        path: '/',
        name: 'CheckoutPlans',
        component: () => import('@/pages/checkout/plans.vue'),
      },
      {
        path: 'payment',
        component: () => import('@/pages/checkout/payment.vue'),
        children: [
          {
            path: '/payment-method',
            name: 'PaymentMethod',
            component: () => import('@/pages/checkout/paymentMethod.vue'),
          },
          {
            path: '/invoicing',
            name: 'PaymentInvoicing',
            component: () => import('@/pages/checkout/invoicing.vue'),
          },
          {
            path: 'credit-card',
            name: 'PaymentCreditCard',
            component: () => import('@/pages/checkout/payments/creditCard.vue'),
          },
          {
            path: 'pix',
            name: 'PaymentPix',
            component: () => import('@/pages/checkout/payments/pix.vue'),
          },
        ],
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
