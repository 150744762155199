<template>
  <b-modal
    :visible="visible"
    id="modal-default"
    :size="size"
  >
    <template v-slot:modal-title>
      <slot name="title"></slot>
    </template>

    <template v-slot:modal-body>
      <slot name="body"></slot>
    </template>
    <template v-slot:modal-footer="{ ok, cancel }">
      <b-button
        variant="outline-secondary"
        @click="cancel()"
      >
        Cancelar
      </b-button>
      <b-button
        variant="primary"
        @click="ok()"
      >
        Salvar
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'ModalDefault',
  props: {
    title: {
      type: String,
      default: 'Modal Title',
    },
    size: {
      type: String,
      default: 'lg',
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    closeModal() {
      this.$bvModal.hide('modal-default');
    },
    openModal() {
      this.$bvModal.show('modal-default');
    },
  },
};
</script>
