const state = {
  user: {},
  plan: {},
};

const getters = {
  user: (state) => state.user,
  plan: (state) => state.plan,
};

const mutations = {
  setUser(state, user) {
    state.user = user;
  },
  setPlan(state, plan) {
    state.plan = plan;
  },
};

const actions = {
  setUser({ commit }, user) {
    commit('setUser', user);
  },
  setPlan({ commit }, plan) {
    commit('setPlan', plan);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
