<template>
  <div
    class="position-relative"
    :class="{ 'primary': variant === 'primary', 'secondary': variant === 'secondary' }"
  >
    <label for="" v-if="label.length > 0">
      {{ label }}
    </label>
    <Multiselect
      :class="{
        'primary': variant === 'primary',
        'secondary': variant === 'secondary',
        'form-group--error': error,
      }"
      v-model="selected"
      :options="options"
      label="name"
      track-by="id"
      :multiple="multiple"
      :placeholder="placeholder"
      selectLabel="Selecionar"
      deselectLabel="Remover"
      @select="$emit('change', selected)"
      @remove="$emit('change', selected)"
    />
    <span class="f-sm fontPoppins fw-500" v-if="error">{{ errorMessage }}</span>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
  name: 'MultipleSelect',
  components: {
    Multiselect,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: 'Selecione',
    },
    label: {
      type: String,
      default: '',
    },
    variant: {
      type: String,
      default: 'primary',
    },
  },
  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    reset() {
      this.value = '';
    },
  },
};
</script>

<style lang="scss" scoped>
label {
  font-family: 'Popins', sans-serif;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 1px;
  background: #fff;
  display: inline !important;
  padding: 5px 10px;
  position: absolute;
  z-index: 9;
  color: #7254A6;
  top: -13px;
  left: 13px;
}
span {
  left: 5px;
  bottom: -20px;
  color: #F31155;
  letter-spacing: 0.5px;
}

.form-group--error {
  border-radius: 8px;
}
</style>
