// * eslint-disable no-shadow */
const state = {
  status: 'invoicing',
  id: '',
};

const getters = {
  payment: (state) => state.payment,
  status: (state) => state.status,
  id: (state) => state.id,
};

const mutations = {
  setStatus(state, status) {
    state.status = status;
  },
  setId(state, id) {
    state.id = id;
  },
};

const actions = {
  setStatus({ commit }, status) {
    commit('setStatus', status);
  },
  setId({ commit }, id) {
    commit('setId', id);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
